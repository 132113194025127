const cards = document.querySelectorAll('.card');
for (let i = 0; i < cards.length; i++) {
  const card = cards[i];
  card.addEventListener('mousemove', startRotate);
  card.addEventListener('mouseout', stopRotate);
}
function startRotate(event) {
  const cardItem = this.querySelector('.card__item');
  const halfHight = cardItem.offsetHeight / 2;
  const halfWidth = cardItem.offsetWidth / 2;
  cardItem.style.transform = 'rotateX(' + -(event.offsetY - halfHight) / 8 + 'deg) rotateY(' + (event.offsetX - halfWidth) / 8 + 'deg) translateZ(80px)';
}
function stopRotate(event) {
  const cardItem = this.querySelector('.card__item');
  cardItem.style.transform = 'rotate(0)';
}
