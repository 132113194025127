    // Start Modal-phone
    $('.description__btn').on('click',function() {
      $('.wrapper-modal').fadeIn();
      console.log('Этот текст будет выведен в консоль');
    });
    $('.form-modal').on('click',function() {
      $('.wrapper-modal').fadeOut();
    });
    $('.form-phone__close').on('click',function() {
      $('.wrapper-modal').fadeOut();
    });
    $('.overlay-modal').on('click',function() {
      $('.wrapper-modal').fadeOut();
    });
    $('.form-services').children().on('click',function(e) {
      e.stopPropagation();
    });
    $('.modal-sent').on('click',function() {
      $('.wrapper-phone').fadeOut();
    });
    $('.modal-sent__btn').on('click',function() {
      $('.wrapper-phone').fadeOut();
    });
    // END Modal-phone

    // Start Modal-phone
    $('.footer-contacts__btn').on('click',function() {
      $('.wrapper-modal__phone').fadeIn();
      console.log('Этот текст будет выведен в консоль');
    });
    $('.form-modal__phone').on('click',function() {
      $('.wrapper-modal__phone').fadeOut();
    });
    $('.overlay-modal').on('click',function() {
      $('.wrapper-modal__phone').fadeOut();
    });
