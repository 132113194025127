import './_vendor';
import './_vars';
import './_functions';
import './_components';
import './components/modal';
import './components/card';
import './components/tabs';
import './components/validation';
import './components/gallery';
import './components/swiper';
import './components/file';


window.onload = function () {
      document.body.classList.add('loaded_hiding');
      window.setTimeout(function () {
        document.body.classList.add('loaded');
        document.body.classList.remove('loaded_hiding');
      }, 500);
    }



$(function() {
 let header = $('.header');

 $(window).scroll(function() {
   if($(this).scrollTop() > 100) {
    header.addClass('header_fixed');
   } else {
    header.removeClass('header_fixed');
   }
 });
});


let accordion = document.querySelectorAll('.accordion')
    accordion.forEach(el => {
        el.addEventListener('click', () => {
            let content = el.childNodes[5]
            let btn = el.childNodes[3]
            console.log(el.childNodes)

            if (content.style.maxHeight) {
              document.querySelectorAll('.accordion__content').forEach(el => el.style.maxHeight = null)
              btn.style.transform = 'rotate(0deg)'
            } else {
              document.querySelectorAll('.accordion__content').forEach(el => el.style.maxHeight = null)
              content.style.maxHeight = content.scrollHeight + 'px';
              btn.style.transform = 'rotate(180deg)'
            }
        })
    });
